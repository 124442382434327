import React, { FunctionComponent } from 'react'
import { Card } from './card/Card'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../styles/styles'
import { Common_AppNote, Common_Close } from '../../translations/messages'
import { useIntl } from 'react-intl'
import { Button } from './Buttons'

const StyledCard = styled(Card)`
  margin-bottom: ${spacing.md};
  padding: ${spacing.md};
`

const Warning = styled.div`
  margin-bottom: ${spacing.md};
  border-radius: ${borderRadius};
  padding: ${spacing.md};
  background: ${colors.trustLight};
`
type Props = {
  onClose: () => void
}

export const AppNoteContentCard: FunctionComponent<Props> = ({ onClose }) => {
  const intl = useIntl()

  return (
    <StyledCard>
      <Warning>{intl.formatMessage(Common_AppNote)}</Warning>
      <Button
        ariaLabel={intl.formatMessage(Common_Close)}
        variant="primary"
        label={intl.formatMessage(Common_Close)}
        onClick={() => {
          localStorage.setItem('show-app-login-warning', 'false')
          onClose()
        }}
        isSmall
      />
    </StyledCard>
  )
}
