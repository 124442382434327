import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles/styles'
import { TranslatedMessage } from '../../../types'
import { FormattedMessage } from 'react-intl'

const TextAreaFieldStyled = styled.div`
  margin-bottom: ${spacing.md};
`

const Label = styled.label`
  display: block;
  width: 100%;
  text-transform: uppercase;
`

const Input = styled.textarea`
  width: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid ${colors.black80};
  font-size: inherit;
  font-family: inherit;
  padding: ${spacing.sm} 0;
  resize: none;
`

type Props = {
  id: string
  label: TranslatedMessage
  value: string
  required?: boolean
  rows?: number
  onChange: (value: string) => void
}

export const TextAreaField: FunctionComponent<Props> = ({
  id,
  label,
  value,
  required,
  rows = 1,
  onChange,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [textAreaHeight, setTextAreaHeight] = useState('auto')
  const [parentHeight, setParentHeight] = useState('auto')

  const parentStyle: CSSProperties = {
    minHeight: parentHeight,
  }

  const textAreaStyle: CSSProperties = {
    height: textAreaHeight,
  }

  useEffect(() => {
    setParentHeight(`${textAreaRef.current!.scrollHeight}px`)
    setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`)
  }, [value])

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight('auto')
    setParentHeight(`${textAreaRef.current!.scrollHeight}px`)
    onChange(event.target.value)
  }

  return (
    <TextAreaFieldStyled>
      <Label htmlFor={id}>
        <FormattedMessage id={label.id} defaultMessage={label.defaultMessage} />
      </Label>
      <div style={parentStyle}>
        <Input
          id={id}
          value={value}
          rows={rows}
          required={required}
          onChange={onChangeHandler}
          ref={textAreaRef}
          style={textAreaStyle}
        />
      </div>
    </TextAreaFieldStyled>
  )
}
